<template>
  <static-template>
    <h1 class="heading-size-3 mt-12 mb-12">Employment</h1>
    <div class="employment-message">
      <div>
        <p>
          {{$t('employmentPhrase1')}}
        </p>
        <p>
          {{$t('employmentPhrase2')}}
        </p>
      </div>
      <img src="@/assets/employment/undraw_fitting_pieces_cli3.svg" alt="" />
    </div>
  </static-template>
</template>

<script>
export default {
  name: 'employment-page',
};
</script>
<style scoped lang="scss">
.employment-message {
  display: flex;
  align-items: center;
  img {
    max-width: 50%;
  }
}
</style>
